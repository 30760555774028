@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  font-family: "Lexend", sans-serif;
  box-sizing: border-box;
}
.screenContainer {
  background-color: #171712;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.screenSubContainer {
  width: 1050px;
}
.header {
  color: white;
  font-size: 36px;
  font-weight: 600;
  margin: 25px 0 18px;
}
.description {
  margin: 5px 0;
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}

.subHeader {
  color: white;
  font-size: 28px;
  font-weight: 600;
  margin: 30px 0 10px;
}
.list {
  color: white;
  margin: 5px 0;
}
.list > li {
  margin: 8px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
}
.list > li > b {
  font-weight: 800;
}
.link {
  color: rgb(171, 25, 255);
}
.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0;
}
.bottom > .button {
  text-decoration: 0;
  color: #fff;
  border: 2.5px solid white;
  padding: 10px 24px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .screenSubContainer {
    width: 750px;
  }
}
@media screen and (max-width: 850px) {
  .screenSubContainer {
    width: 500px;
  }
  .header {
    font-size: 28px;
    margin: 20px 0 15px;
  }
  .description {
    margin: 4px 0;
    font-size: 14px;
    line-height: 21px;
  }
  .imgI {
    display: none;
  }

  .subHeader {
    color: white;
    font-size: 22px;
    font-weight: 600;
    margin: 24px 0 8px;
  }
  .list {
    color: white;
    margin: 4px 0;
  }
  .list > li {
    margin: 6.5px 0;
    font-size: 14px;
    line-height: 20px;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0;
  }
  .bottom > .button {
    border: 2px solid white;
    padding: 7px 18px;
    border-radius: 6px;
    font-size: 17px;
  }
}
@media screen and (max-width: 550px) {
  .screenSubContainer {
    width: 300px;
  }
  .header {
    font-size: 22px;
    margin: 18px 0 12px;
  }
  .description {
    margin: 4px 0;
    font-size: 11.5px;
    line-height: 17px;
  }
  .imgI {
    display: none;
  }

  .subHeader {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin: 18px 0 6px;
  }
  .list {
    color: white;
    margin: 4px 0;
  }
  .list > li {
    margin: 4.5px 0;
    font-size: 11.5px;
    line-height: 20px;
  }
  .bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0;
  }
  .bottom > .button {
    border: 1px solid white;
    padding: 4px 12px;
    border-radius: 6px;
    font-size: 12px;
  }
}
